@import 'additional-styles/base.css';
/* @import 'tailwindcss/base'; */
@import 'tailwindcss/components';

/* Additional styles */
@import 'additional-styles/utility-patterns.css';
@import 'additional-styles/range-slider.css';
@import 'additional-styles/toggle-switch.css';
@import 'additional-styles/theme.css';

@import 'tailwindcss/utilities';

html {
    scroll-behavior: smooth;
}

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
    .rtl {
        direction: rtl;
    }
}

